import React from "react"
import { Link } from "gatsby"

import AdminLayout from "../components/adminPanelLayout"
import SEO from "../components/seo"
import "../styles/adminPanel.scss"

const AdminPanel = () => (
  <AdminLayout>
    <SEO title="Page two" />
    <h1>Hi from the second page</h1>
    <p style={{ height: "1000px" }}>Welcome to page 2</p>
    <p style={{}}>Welcome to page 3</p>
    <Link to="/">Go back to the homepage</Link>
  </AdminLayout>
)

export default AdminPanel
